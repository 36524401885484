<template lang="pug">
  div
    CustomSelect.custom-select(
      componentName="dashboard-store-group-filter"
      v-model="storeGroupFilter.id"
      optionTitle="name"
      :isSearchActive="true"
      selectedEmitValue="id"
      :optionData="storeGroupList"
      :isDefaultTextActive="false"
      @input="submitFilter"
    )
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import CustomSelect from '@/view/global/custom-select'

export default {
  name: 'storeGroupSelect',

  data () {
    return {
      storeGroupFilter: null
    }
  },

  components: {
    CustomSelect
  },

  async created () {
    await this.getStoreGroupList({pageNumber: 1, pageSize: 99999})
    this.storeGroupFilter = this.storeGroupList[0]
    this.$emit('setDefaultStoreGroup', this.storeGroupFilter)
  },


  computed: {
    ...mapGetters('StoreGroups', [
      'storeGroupList'
    ]),

    isLoading () {
      return this.$wait.is(['getStoreGroupList'])
    }
  },

  methods: {
    ...mapActions('StoreGroups', [
      'getStoreGroupList'
    ]),

    submitFilter () {
      this.$emit('submitFilter', this.storeGroupFilter)
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep() .select-dropdown-custom.custom-select {
    .select-dropdown-custom-options {
      &.upside {
        width: 100% !important;
      }
      &.normal {
        width: 100% !important;
      }
    }
    .select-dropdown-custom-selected {
      width: 100% !important;
      height: 54px !important;
    }
    .select-dropdown-custom-name {
      font-size: $font-size-normal !important;
    }
    .selected-name {
      font-size: $font-size-normal !important;
    }
  }
  :deep() .select-dropdown-custom-options-search .txt {
    font-size: $font-size-normal !important;
  }
</style>
