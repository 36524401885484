var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CustomSelect", {
        staticClass: "custom-select",
        attrs: {
          componentName: "dashboard-store-group-filter",
          optionTitle: "name",
          isSearchActive: true,
          selectedEmitValue: "id",
          optionData: _vm.storeGroupList,
          isDefaultTextActive: false,
        },
        on: { input: _vm.submitFilter },
        model: {
          value: _vm.storeGroupFilter.id,
          callback: function ($$v) {
            _vm.$set(_vm.storeGroupFilter, "id", $$v)
          },
          expression: "storeGroupFilter.id",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }