import Dashboard from '../index'

export default [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      main_menu: true,
      slug: 'dashboard',
      page: 'dashboard'
    }
  }
]
