<template lang="pug">
div.day-message
  Icon(:name="timeOfDayIcon")
  span.day-text {{ timeOfDayMessage }},
  span.day-text.user {{ fullName }}! 
</template>

<script>
import {mapGetters} from 'vuex'
import AuthorizationContext from "@/sso/keycloak-instance";

export default {
  name: 'dayMessage',

  data () {
    return {
      timeOfDay: 'morning'
    }
  },

  created () {
    this.checkHoursOfDay()
  },

  computed: {
    ...mapGetters('Global', [
        'isSupportUser'
      ]),
      ...mapGetters('Auth', [
      'Profile'
    ]),
    timeOfDayIcon () {
      return this.timeOfDay === 'evening' ? 'icon-dashboard-evening' : 'icon-dashboard-morning'
    },
    timeOfDayMessage () {
      return this.$t(`Dashboard.${this.timeOfDay}_message`)
    },
    fullName () {
      if (this.isSupportUser) {
        return this.Profile.user.firstname + ' ' + this.Profile.user.surname
      }
      const parsedToken = AuthorizationContext.token
      return parsedToken.given_name.trim() + ' ' + parsedToken.family_name.trim()
    }
  },

  methods: {
    checkHoursOfDay () {
      let dateHour = new Date().getHours();
      if (dateHour >= 6 && dateHour < 12) this.timeOfDay = 'morning'
      else if (dateHour >= 12 && dateHour < 18) this.timeOfDay = ' afternoon'
      else this.timeOfDay = 'evening'
    }
  }
}
</script>

<style scoped lang="scss">

  .day-message {
    display: flex;
    align-items: center;
    .day-text {
      color: $color-light;
      font-size: $font-size-bigger;
      margin-left: 10px;
      &.user {
        color: $color-dark;
      }
    }
  }
</style>
