<template lang="pug">
.card-box(
  :id="`card-box-${rowId}`", @click="$emit('click')"
  )
  .card-box-top
    .card-box-top-text
      p.card-box-top-text-info.m-right-5(:id="`card-box-text-${rowId}`") {{ infoText }}
      p.card-box-top-text-info.light(v-if="useSecondInfoText" :id="`card-box-second-info-text-${rowId}`") / {{secondInfoText}}

    .card-box-top-percent(
      :class="status"
    )
      Icon(
        :name="status === 'positive' ? 'icon-up-arrow' : 'icon-down-arrow'"
        v-if="status !== 'neutral'"
      )
      span.card-box-top-percent-info {{ percent ?  '%' + ($options.filters.percentFixed(percent)) : '-' }}
  .card-box-bottom
    .value.m-right-5(:id="`card-box-value-${rowId}`") {{value | formatCurrency2Digits}}
      .second-value(v-if="secondValue" :id="`card-box-value-${rowId}`") / {{secondValue | formatCurrency2Digits}}
    span.summary(v-if="summary") {{ (periodType === 1) ? $t('Dashboard.store_period_selection-weekly-text') : $t('Dashboard.store_period_selection-monthly-text') }}
    span.summary(v-if="summary" :id="`card-box-summary-${rowId}`") : {{summary | formatCurrency2Digits}}
    .date(v-if="date.length") {{ getDate(date) }}
</template>

<script>

export default {
  name: 'card-box',
  props: {
    infoText: {
      type: String,
      default: null
    },
    percent: {
      required: true
    },
    value: {
      type: Number,
      default: null
    },
    summary: {
      type: Number,
      default: null
    },
    date: {
      type: Array,
      default: () => []
    },
    revertStatus: {
      type: Boolean,
      default: false
    },
    rowId: {
      type: Number
    },
    periodType: {
      type: Number
    },
    useSecondInfoText: {
      type: Boolean,
      default: false
    },
    secondInfoText: {
      type: String,
      default: ''
    },
    secondValue: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      monthIndex: new Date().getMonth()
    }
  },
  computed: {
    status () {
      const positive = this.revertStatus ? 'negative' : 'positive'
      const negative = this.revertStatus ? 'positive' : 'negative'
      return !this.percent ? 'neutral' : this.percent > 0 ? positive : negative
    }
  },

  methods: {
    getDate(date) {
      return moment(date[0]).format('DD/MM/YYYY') + '-' + moment(date[1]).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped lang="scss">

.card-box {
  background: $color-light-gray;
  border-radius: $border-radius;
  padding: 20px;
  cursor: pointer;
  @media (max-width: 1280px) {
    margin-top: 20px;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
    &-text {
      display: flex;
      &-info {
        font-size: $font-size-small;
        color: $color-dark;
      }
    }
    &-percent {
      min-height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius;
      padding: 0 10px;
      &.positive {
        background-color: $color-success;
      }
      &.negative {
        background-color: $color-warning;
      }
      &.neutral {
        background-color: $color-light;
      }
      &-info {
        color: $color-white;
        font-size: $font-size-micro;
      }
      .icon {
        width: 12px;
        height: 8px;
        color: $color-white;
        margin-right: 3px;
      }
    }
  }
  &-bottom {
    .value {
      font-size: $font-size-Hbigger;
      color: $color-dark;
      margin-bottom: 6px;
      display: flex;
      font-weight: $font-weight-semi-bold;
      align-items: center;
    }
    .second-value{
      font-size: 20px;
      color: $color-light;
      font-weight: $font-weight-normal;
    }
    .summary {
      font-size: $font-size-small;
      color: $color-light;
    }
  }
}
.light {
  color: $color-light !important; 
}
.date {
  color: $color-light;
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
}
</style>
