<template lang="pug">
CustomMultiselect(
    id="payment-types-filter"
    :items="storeList"
    :selected="storeFilter"
    :showSelectedName="true"
    :popupHeader="$t('Request.toolbar_Stores_PopupHeader')"
    :allLabel="$t('Request.toolbar_Stores_AllLabel')"
    :buttonText="$t('Request.toolbar_Stores_ButtonText')"
    :showCountBadge="false"
    @delegateCheckAll="onChangeFilter"
    @delegateCheckItem="onChangeFilter"
    @delegateSubmitItems="submitFilter"
    ref="storeFilter")
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'storeSelect',

  props: {
    userDefaultStoreId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      storeFilter: []
    }
  },

  async mounted () {
    await this.getStoreList({pageNumber: 1, pageSize: 9999})
    const selectedStore = this.storeList.find(item => item.id === this.userDefaultStoreId)
    this.storeFilter.push(selectedStore)
    this.$refs.storeFilter.beforeSubmit()
  },

  computed: {
    ...mapGetters('Stores', [
      'storeList'
    ]),

    isLoading () {
      return this.$wait.is(['getStoreList'])
    }
  },

  methods: {
    ...mapActions('Stores', [
      'getStoreList'
    ]),

    onChangeFilter (event) {
      if (event.isChecked) {
        if (event.item.all) {
          this.storeFilter = [...this.storeList]
        } else {
          this.storeFilter.push(event.item)
        }
      } else {
        if (event.item.all) {
          this.storeFilter = []
        } else {
          this.storeFilter.splice(this.storeFilter.indexOf(event.item), 1)
        }
      }
    },
    submitFilter (dontSubmit = false) {
      this.$emit('submitFilter', {list: this.storeFilter, dontSubmit})
    }
  }
}
</script>

<style lang="scss" scoped>

  :deep() .has-values {
    color: #000!important;
    font-size: $font-size-small!important;
    height: 44px !important;
  }
  :deep() .dropdown-handle-button-values {
    line-height: 42px!important;
  }
  :deep() .popup {
    width: 100%!important;
  }
</style>
