var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "day-message" },
    [
      _c("Icon", { attrs: { name: _vm.timeOfDayIcon } }),
      _c("span", { staticClass: "day-text" }, [
        _vm._v(_vm._s(_vm.timeOfDayMessage) + ","),
      ]),
      _c("span", { staticClass: "day-text user" }, [
        _vm._v(_vm._s(_vm.fullName) + "! "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }