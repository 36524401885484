var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "content-top" }, [
      _c("div", { staticClass: "tabs" }, [
        _c(
          "a",
          {
            staticClass: "report-tab",
            class: { isSelected: _vm.viewType === 0 },
            attrs: { id: "option-refrigerator-data-current" },
            on: {
              click: function ($event) {
                return _vm.periodFilter(0)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Dashboard.device_period_selection_current")))]
        ),
        _c(
          "a",
          {
            staticClass: "report-tab",
            class: { isSelected: _vm.viewType === 1 },
            attrs: { id: "option-refrigerator-data-history" },
            on: {
              click: function ($event) {
                return _vm.periodFilter(1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("Dashboard.device_period_selection_history")))]
        ),
      ]),
      _c(
        "div",
        { staticClass: "value-filter" },
        [
          _vm.viewType === 0
            ? _c("span", { staticClass: "value-filter-title" }, [
                _vm._v(_vm._s(_vm.$t("Dashboard.refrigerator_lastUpdateDate"))),
              ])
            : _vm._e(),
          _vm.viewType === 0
            ? _c("span", { staticClass: "value-filter-date" }, [
                _vm._v(
                  _vm._s(_vm.getUpdateDate(_vm.currentData[0].serverTime))
                ),
              ])
            : _vm._e(),
          _c("CustomSelect", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.viewType === 1,
                expression: "viewType === 1",
              },
            ],
            attrs: {
              name: "refrigerator-data-type",
              value: _vm.historySelectedData.valueId,
              selectedEmitValue: "valueId",
              optionTitle: "label",
              optionData: _vm.historyData,
              isDefaultTextActive: false,
              componentName: "dashboard-devices-value-select",
            },
            on: { inputSelected: _vm.changeDeviceValue },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "refrigerator-table" }, [
      _c("div", { staticClass: "refrigerator-table-main" }, [
        _vm.viewType === 0
          ? _c(
              "table",
              { staticClass: "debt-table", attrs: { id: `debt-table` } },
              [
                _c(
                  "thead",
                  {
                    staticClass: "debt-table-head",
                    attrs: { id: `debt-table-head` },
                  },
                  [
                    _c(
                      "tr",
                      {
                        staticClass: "table-row",
                        attrs: { id: `debt-table-head-row` },
                      },
                      [
                        _c(
                          "th",
                          {
                            staticClass: "table-col",
                            attrs: { id: `col-title-current-data` },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("Dashboard.refriegerator_data"))
                            ),
                          ]
                        ),
                        _c("th", {
                          staticClass: "table-col",
                          attrs: { id: `col-title-current-data-icon` },
                        }),
                        _c(
                          "th",
                          {
                            staticClass: "table-col",
                            attrs: { id: `col-title-current-data-value` },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("Dashboard.refrigerator_value"))
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "tbody",
                  { staticClass: "debt-table-body" },
                  _vm._l(_vm.currentData, function (data, index) {
                    return _c(
                      "tr",
                      {
                        staticClass: "table-row",
                        attrs: { id: `debt-table-body-row` },
                      },
                      [
                        _c(
                          "td",
                          {
                            staticClass: "table-col",
                            attrs: { id: `col-title-current-data-${index}` },
                          },
                          [_vm._v(_vm._s(data.label))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "table-col",
                            attrs: {
                              id: `col-title-current-data-icon-${index}`,
                            },
                          },
                          [
                            _c("Icon", {
                              staticClass: "thermometer-hot",
                              attrs: { name: "thermometer-hot" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "table-col debt-total",
                            attrs: {
                              id: `col-title-current-data-value-${index}`,
                            },
                          },
                          [_vm._v(_vm._s(data.value) + " ")]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
        _vm.viewType === 1
          ? _c(
              "table",
              { staticClass: "debt-table", attrs: { id: `debt-table` } },
              [
                _c(
                  "thead",
                  {
                    staticClass: "debt-table-head",
                    attrs: { id: `debt-table-head` },
                  },
                  [
                    _c(
                      "tr",
                      {
                        staticClass: "table-row",
                        attrs: { id: `debt-table-head-row` },
                      },
                      [
                        _c(
                          "th",
                          {
                            staticClass: "table-col",
                            attrs: { id: `col-history-data-date` },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("Dashboard.refriegerator_dateAndTime")
                              )
                            ),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "table-col",
                            attrs: { id: `col-history-data` },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("Dashboard.refrigerator_data"))
                            ),
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticClass: "table-col",
                            attrs: { id: `col-history-data-value` },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("Dashboard.refrigerator_value"))
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "tbody",
                  { staticClass: "debt-table-body" },
                  _vm._l(_vm.displayedItems, function (data, index) {
                    return _c(
                      "tr",
                      {
                        staticClass: "table-row",
                        attrs: { id: `debt-table-body-row` },
                      },
                      [
                        _c(
                          "td",
                          {
                            staticClass: "table-col date-time",
                            attrs: {
                              id: `col-history-data-server-time-${index}`,
                            },
                          },
                          [
                            _c("div", { staticClass: "time" }, [
                              _vm._v(_vm._s(_vm.formatTime(data.serverTime))),
                            ]),
                            _c("div", { staticClass: "date" }, [
                              _vm._v(
                                _vm._s(_vm.formatDate(data.serverTime)) + " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "table-col",
                            attrs: { id: `col-history-data-label-${index}` },
                          },
                          [_vm._v(_vm._s(_vm.historySelectedData.label))]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "table-col debt-total",
                            attrs: { id: `col-history-data-value-${index}` },
                          },
                          [_vm._v(_vm._s(data.value) + " ")]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm.viewType === 1
      ? _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("Button", {
              staticClass: "prev",
              attrs: {
                iconName: "icon-previous",
                iconClass: "icon-previous",
                disabled: _vm.currentPage === 1,
              },
              on: { click: _vm.previousPage },
            }),
            _c(
              "Button",
              {
                class: { active: _vm.currentPage === 1 },
                on: {
                  click: function ($event) {
                    return _vm.changePage(1)
                  },
                },
              },
              [_vm._v("1")]
            ),
            _vm.currentPage > 3 &&
            _vm.totalPages > 6 &&
            _vm.pagesToShow[1] !== 2
              ? _c("Button", [_vm._v("...")])
              : _vm._e(),
            _vm._l(_vm.pagesToShow, function (pageNumber) {
              return _c(
                "Button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: pageNumber !== 1 && pageNumber !== _vm.totalPages,
                      expression:
                        "pageNumber !== 1 && pageNumber !== totalPages",
                    },
                  ],
                  key: pageNumber,
                  class: { active: pageNumber === _vm.currentPage },
                  on: {
                    click: function ($event) {
                      return _vm.changePage(pageNumber)
                    },
                  },
                },
                [_vm._v(_vm._s(pageNumber))]
              )
            }),
            _vm.currentPage < _vm.totalPages - 2 &&
            _vm.totalPages > 6 &&
            _vm.pagesToShow[_vm.pagesToShow.length - 2] !== _vm.totalPages - 1
              ? _c("span", { staticClass: "dots" }, [_vm._v("...")])
              : _vm._e(),
            _c(
              "Button",
              {
                class: { active: _vm.totalPages === _vm.currentPage },
                on: {
                  click: function ($event) {
                    return _vm.changePage(_vm.totalPages)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.totalPages))]
            ),
            _c("Button", {
              staticClass: "next",
              attrs: {
                iconName: "icon-next",
                iconClass: "icon-next",
                disabled: _vm.currentPage === _vm.totalPages,
              },
              on: { click: _vm.nextPage },
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }