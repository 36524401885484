var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "card-box",
      attrs: { id: `card-box-${_vm.rowId}` },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("div", { staticClass: "card-box-top" }, [
        _c("div", { staticClass: "card-box-top-text" }, [
          _c(
            "p",
            {
              staticClass: "card-box-top-text-info m-right-5",
              attrs: { id: `card-box-text-${_vm.rowId}` },
            },
            [_vm._v(_vm._s(_vm.infoText))]
          ),
          _vm.useSecondInfoText
            ? _c(
                "p",
                {
                  staticClass: "card-box-top-text-info light",
                  attrs: { id: `card-box-second-info-text-${_vm.rowId}` },
                },
                [_vm._v("/ " + _vm._s(_vm.secondInfoText))]
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "card-box-top-percent", class: _vm.status },
          [
            _vm.status !== "neutral"
              ? _c("Icon", {
                  attrs: {
                    name:
                      _vm.status === "positive"
                        ? "icon-up-arrow"
                        : "icon-down-arrow",
                  },
                })
              : _vm._e(),
            _c("span", { staticClass: "card-box-top-percent-info" }, [
              _vm._v(
                _vm._s(
                  _vm.percent
                    ? "%" + _vm.$options.filters.percentFixed(_vm.percent)
                    : "-"
                )
              ),
            ]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "card-box-bottom" }, [
        _c(
          "div",
          {
            staticClass: "value m-right-5",
            attrs: { id: `card-box-value-${_vm.rowId}` },
          },
          [
            _vm._v(_vm._s(_vm._f("formatCurrency2Digits")(_vm.value))),
            _vm.secondValue
              ? _c(
                  "div",
                  {
                    staticClass: "second-value",
                    attrs: { id: `card-box-value-${_vm.rowId}` },
                  },
                  [
                    _vm._v(
                      "/ " +
                        _vm._s(_vm._f("formatCurrency2Digits")(_vm.secondValue))
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm.summary
          ? _c("span", { staticClass: "summary" }, [
              _vm._v(
                _vm._s(
                  _vm.periodType === 1
                    ? _vm.$t("Dashboard.store_period_selection-weekly-text")
                    : _vm.$t("Dashboard.store_period_selection-monthly-text")
                )
              ),
            ])
          : _vm._e(),
        _vm.summary
          ? _c(
              "span",
              {
                staticClass: "summary",
                attrs: { id: `card-box-summary-${_vm.rowId}` },
              },
              [
                _vm._v(
                  ": " + _vm._s(_vm._f("formatCurrency2Digits")(_vm.summary))
                ),
              ]
            )
          : _vm._e(),
        _vm.date.length
          ? _c("div", { staticClass: "date" }, [
              _vm._v(_vm._s(_vm.getDate(_vm.date))),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }