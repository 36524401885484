<template lang="pug">
  div
    .content-top
      .tabs
        a.report-tab(
        id="option-refrigerator-data-current"
        :class="{isSelected: viewType === 0}"
        @click="periodFilter(0)"
        ) {{ $t('Dashboard.device_period_selection_current') }}
        a.report-tab(
        id="option-refrigerator-data-history"
        :class="{isSelected: viewType === 1}"
        @click="periodFilter(1)"
        ) {{ $t('Dashboard.device_period_selection_history') }}
      
      .value-filter
        span.value-filter-title(v-if="viewType === 0") {{ $t('Dashboard.refrigerator_lastUpdateDate') }}
        span.value-filter-date(v-if="viewType === 0") {{ getUpdateDate(currentData[0].serverTime)}}

        CustomSelect(
          v-show="viewType === 1"
          name="refrigerator-data-type"
          :value="historySelectedData.valueId"
          selectedEmitValue="valueId"
          optionTitle="label"
          :optionData="historyData"
          :isDefaultTextActive="false"
          componentName="dashboard-devices-value-select"
          @inputSelected="changeDeviceValue"
        )

    .refrigerator-table
      .refrigerator-table-main
        table.debt-table(:id="`debt-table`" v-if="viewType === 0")
          thead.debt-table-head(:id="`debt-table-head`")
            tr.table-row(:id="`debt-table-head-row`")
              th.table-col(:id="`col-title-current-data`") {{ $t('Dashboard.refriegerator_data') }}
              th.table-col(:id="`col-title-current-data-icon`") 
              th.table-col(:id="`col-title-current-data-value`") {{ $t('Dashboard.refrigerator_value') }}
          tbody.debt-table-body
            tr.table-row(
              :id="`debt-table-body-row`"
              v-for="(data, index) in currentData")
              td.table-col(:id="`col-title-current-data-${index}`") {{ data.label }}
              td.table-col(:id="`col-title-current-data-icon-${index}`") 
                Icon(name="thermometer-hot" class="thermometer-hot")
              td.table-col.debt-total(:id="`col-title-current-data-value-${index}`") {{ data.value }} 
      
        table.debt-table(:id="`debt-table`" v-if="viewType === 1")
          thead.debt-table-head(:id="`debt-table-head`")
            tr.table-row(:id="`debt-table-head-row`")
              th.table-col(:id="`col-history-data-date`") {{ $t('Dashboard.refriegerator_dateAndTime') }}
              th.table-col(:id="`col-history-data`") {{ $t('Dashboard.refrigerator_data') }}
              th.table-col(:id="`col-history-data-value`") {{ $t('Dashboard.refrigerator_value') }}
          tbody.debt-table-body
            tr.table-row(
              :id="`debt-table-body-row`"
              v-for="(data, index) in displayedItems")
              td.table-col.date-time(:id="`col-history-data-server-time-${index}`") 
                .time {{ formatTime(data.serverTime) }}
                .date {{ formatDate(data.serverTime) }} 
              td.table-col(:id="`col-history-data-label-${index}`") {{ historySelectedData.label }}
              td.table-col.debt-total(:id="`col-history-data-value-${index}`") {{ data.value }} 
    .pagination(v-if="viewType === 1")
      Button(@click="previousPage" iconName="icon-previous" iconClass="icon-previous" :disabled="currentPage === 1" class="prev")
      Button(@click="changePage(1)" :class="{ 'active': currentPage === 1 }") 1
      Button(v-if="currentPage > 3 && totalPages > 6 && pagesToShow[1] !== 2") ...
      Button(v-for="pageNumber in pagesToShow" :key="pageNumber" @click="changePage(pageNumber)" :class="{ 'active': pageNumber === currentPage }" v-show="pageNumber !== 1 && pageNumber !== totalPages") {{ pageNumber }}
      span.dots(v-if="currentPage < totalPages - 2 && totalPages > 6 && pagesToShow[pagesToShow.length - 2] !== totalPages - 1") ...
      Button(@click="changePage(totalPages)" :class="{ 'active': totalPages === currentPage }") {{ totalPages }}
      Button(@click="nextPage" iconName="icon-next" iconClass="icon-next" :disabled="currentPage === totalPages" class="next") 
</template>

<script>
import {mapGetters} from 'vuex'

//components
import CustomSelect from '@/view/global/custom-select'
import TableFoot from '@/view/global/table/foot'

export default {
  name: 'refrigerator-table',
  data () {
    return {
      viewType: 0,
      historySelectedData: null,
      itemsPerPage: 6, // Sayfa başına öğe sayısı
      currentPage: 1 
    }
  },

  components: {
    CustomSelect,
    TableFoot
  },

  props: {
    currentData: {
      type: Array,
      default: () => []
    },
    historyData: {
      type: Array,
      default: () => []
    },
  },

  created () {
    this.historySelectedData = [...this.historyData][0]
  },

  computed: {
    ...mapGetters('Dashboard', [
      'Despatch'
    ]),

    totalPages() {
      return Math.ceil(this.historySelectedData.data.length / this.itemsPerPage) // Toplam sayfa sayısını hesaplar
    },
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage // Mevcut sayfanın başlangıç indeksi
      const endIndex = startIndex + this.itemsPerPage // Mevcut sayfanın bitiş indeksi
      return this.historySelectedData.data.slice(startIndex, endIndex) // Verileri filtreleyerek mevcut sayfaya göre görüntüler
    },

    refrigeratorHistory () {
      return {
        number: 1,
        size: 6,
        total: this.historySelectedData.data.length
      }
    },
    pagesToShow() {
      let pages = [];
      const currentPage = this.currentPage;
      const totalPages = this.totalPages;
      const maxPages = 3; // Gösterilecek maksimum sayfa sayısı
      let startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
      let endPage = Math.min(totalPages, startPage + maxPages - 1);

      if (totalPages <= maxPages) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        if (currentPage <= Math.floor(maxPages / 2) + 1) {
          endPage = maxPages;
        } else if (currentPage >= totalPages - Math.floor(maxPages / 2)) {
          startPage = totalPages - maxPages + 1;
        }
        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }
      }

      return pages;
    },
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    getUpdateDate (date) {
      return moment(date).format('DD MMM, YYYY - HH:mm:ss', this.$t)
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber; // Tıklanan sayfaya göre sayfayı değiştirir
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    },

    formatDate (date) {
      return moment(date).format('DD MMMM YYYY', this.$t)
    },
    formatTime (time) {
      return moment(time).format('HH:mm:ss', this.$t)
    },
    periodFilter (type) {
      this.viewType = type
    },
    changeDeviceValue (item) {
      this.historySelectedData = item
    }
  }
}
</script>

<style scoped lang="scss">

.refrigerator-table {
  margin-top: 14px;
  overflow: auto;
  max-height: 414px !important;
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      .refrigerator-title {
        font-size: $font-size-big;
        color: $color-sidebar-dark;
      }
    }
    .slide {
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $color-light;
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
        &.active {
          background-color: $color-success;
        }
      }
    }
  }
  &-main {
    .debt-table {
      .table-row {
        .table-col {
          font-size: $font-size-small;
          width: 25%;
          padding: 8px 16px;
        }
      }

      &-head {
        color: #505050;
        text-align: left;
        font-size: 14px;
        border: 1px solid #eaeaeb;
        font-weight: 400;
        .table-row {
          .table-col {
            color: #505050;
            text-align: left;
            &:last-child {
              text-align: right;
            }
          }
        }
      }
      &-body {
        border: 1px solid #eaeaeb;
        .table-row {
          border-bottom: 1px solid $color-gray;
          .table-col {
            width: 25%;
            font-size: 14px;
            color: #8F9BA9;
            text-align: left;
            padding: 16px;
            &:last-child {
              text-align: right;
              color: #505050;
            }
            &.debt-total {
              font-weight: $font-weight-bold;
            }
            &.debt-left {
              color: $color-warning;
            }
          }
        }
      }
      &-footer {
        margin-top: 20px;
        .table-row {
          border-bottom: none;
          .table-col {
            padding: 20px 0;
            text-align: left;
            color: #505050;
            font-weight: 420;
            &:first-child {
              text-align: left;
            }
            &.debt {
              font-size: $font-size-big;
              font-weight: $font-weight-bold;
              @media (max-width: 768px) {
                font-size: $font-size-small;
              }
              &.recieved {
                color: $color-op-gray;
              }
              &.total {
                color: $color-success;
                text-align: right;
              }
              &.left {
                color: $color-warning;
              }
            }
          }
        }
      }
    }
  }
}

.tabs {
  .report-tab {
    color: $color-light;
    margin-right: 20px;
    cursor: pointer;
    font-size: $font-size-big;
    &.isSelected {
      color: #323232;
      pointer-events: none;
      border-bottom: 2px solid $color-success;
    }
  }
}
.content-top {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  align-items: center;
  padding: 10px 0;
}
.thermometer-hot {
  width: 22px !important;
  height: 22px !important;
}


.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: flex-end;
  li {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }

  .dots {
    color: #86939E;
    margin-left: 4px;
  }

  button {
    border: 1px solid #E1E9EE;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-left: 4px;
    font-size: 12px;
    color: #86939E;
    &:hover {
      background-color: #ccc;
    }

    &.active {
      border: 1px solid #26A6FD;
      color: #26A6FD;
      background: rgba(34, 163, 253, 0.05);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
.prev, .next {
  padding: 8px !important;
  .icon {
    width: 6px;
    height: 10px;
    margin-left: 4px;   
  }
}
:deep() .icon-left {
  margin: 4px 0 0 0 !important;
}
.date-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 200px !important;
  .time {
    color: #505050;
    font-weight: bold;
  }
  .date {
    margin-left: 20px;
  }
}

.value-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &-title {
    color: #8F9BA9;
    font-size: 8px;
    text-transform: uppercase; 
    font-weight: 500;
  }
  &-date {
    font-size: 12px;
    color: #505050;
    font-weight: 500;
  }
}
:deep() .select-dropdown-custom-selected {
  height: 36px !important;
}
</style>
