var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CustomMultiselect", {
    ref: "storeFilter",
    attrs: {
      id: "payment-types-filter",
      items: _vm.storeList,
      selected: _vm.storeFilter,
      showSelectedName: true,
      popupHeader: _vm.$t("Request.toolbar_Stores_PopupHeader"),
      allLabel: _vm.$t("Request.toolbar_Stores_AllLabel"),
      buttonText: _vm.$t("Request.toolbar_Stores_ButtonText"),
      showCountBadge: false,
    },
    on: {
      delegateCheckAll: _vm.onChangeFilter,
      delegateCheckItem: _vm.onChangeFilter,
      delegateSubmitItems: _vm.submitFilter,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }